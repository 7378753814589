// This file is automatically generated by `generated_js` task. Any changes will be lost
/* tslint:disable */
export const GoCDVersion = {
  "version": "23.3.0",
  "buildNumber": "16991",
  "gitSha": "f38981e8aa684d63ac96f517996cd888eff37640",
  "fullVersion": "23.3.0-16991",
  "formattedVersion": "23.3.0 (16991-f38981e8aa684d63ac96f517996cd888eff37640)",
  "copyrightYear": "2023"
}


function stripLeadingPrefix(suffix: string, prefix: string) {
  if (suffix.startsWith(prefix)) {
      suffix = suffix.substring(1);
  }
  return suffix;
}

export function docsUrl(suffix: string = '') {
  return `https://docs.gocd.org/23.3.0/${stripLeadingPrefix(suffix, '/')}`
}

export function apiDocsUrl(suffix: string = '') {
  return `https://api.gocd.org/23.3.0/${stripLeadingPrefix(suffix, '#')}`
}
    
